// wpo-breadcumb-area
.wpo-breadcumb-area {
  background: url(../../images/breadcrumb.jpg) no-repeat center top / cover;
  min-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;

  .wpo-breadcumb-wrap {
    ul {
      li {
        display: inline-block;
        padding: 0px 5px;
        color: $dark-gray;
        position: relative;
        font-size: 20px;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:after {
          content: "/";
          position: relative;
          left: 7px;
        }

        &:last-child {
          span {
            color: $dark-gray;
          }
          &:after {
            display: none;
          }
        }

        a {
          color: $dark-gray;
          font-size: 20px;
          transition: all 0.3s;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }
}

// wpo-page-title

.wpo-page-title {
  background-color: #a3888c;
  min-height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 767px) {
    min-height: 250px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #083051;
    content: "";
    z-index: -1;
    opacity: 0.4;
  }
  .wpo-breadcumb-wrap {
    text-align: center;

    h2 {
      font-size: 60px;
      color: #fff;
      line-height: 60px;
      margin-top: -10px;
      margin-bottom: 20px;
      font-weight: 900;

      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 10px;
      }
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
      li {
        display: inline-block;
        padding: 0px 5px;
        color: #fff;
        position: relative;
        font-size: 20px;
        font-family: $heading-font;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:after {
          content: "/";
          position: relative;
          left: 7px;
        }

        &:last-child {
          span {
            color: #cbd4fd;
          }
          &:after {
            display: none;
          }
        }

        a {
          color: #fff;
          font-size: 20px;
          transition: all 0.3s;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }
}
